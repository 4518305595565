import clsx from 'clsx'
import { Link } from '../index'
import Styles from './SocialIcons.module.scss'

import Facebook from '../../../public/icons/facebook-icon.svg'
import Instagram from '../../../public/icons/instagram-icon.svg'
import Twitter from '../../../public/icons/twitter-icon.svg'
import LinkedIn from '../../../public/icons/linkedin-icon.svg'

const SocialIcons = ({ header = true, globalMenus = {}, links = [], className = '' }) => {
  const socialNetworksItems = globalMenus?.socialNetworks?.menuItems?.nodes || []

  const IconSet = {
    facebook: Facebook, // iconLabels: SvgIcons
    twitter: Twitter,
    instagram: Instagram,
    linkedin: LinkedIn,
  }

  const sourceArray = Array.isArray(links) && links?.length ? links : socialNetworksItems

  const filteredSocialItems = sourceArray
    ? sourceArray.filter(item => {
        return typeof IconSet[item?.label?.toLowerCase()] !== 'undefined'
      })
    : []

  return (
    <ul className={clsx('flex space-x-12 md:space-x-15', className)}>
      {filteredSocialItems?.map((item, index) => {
        const DynamicSocialIcon = IconSet[item?.label?.toLowerCase()]

        return (
          <li key={index}>
            <Link href={item?.url}>
              <a
                className={clsx(
                  Styles.social_icons,
                  'pointer flex items-center justify-center rounded-full transition duration-100 ease-in-out',
                  'w-32 h-32 md:w-38 md:h-38',
                  header && 'focus:outline-none focus-visible:outline-black',
                  !header && 'focus:outline-none focus-visible:outline-white'
                )}
                target='_blank'
                rel='noopener noreferrer'>
                <DynamicSocialIcon className='w-full h-14 fill-current object-contain' />
                <span className='sr-only'>{item?.label}</span>
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default SocialIcons
