import clsx from 'clsx'
import { useState } from 'react'
import dynamic from 'next/dynamic'

// Direct Imports
import Provider from '../store' // GlobalStore
import { MegaMenu, BackgroundTintLayer } from '../components'

// Dynamic Imports
const Seo = dynamic(() => import('../components').then(mod => mod.Seo))
const Analytics = dynamic(() => import('../components').then(mod => mod.Analytics))
const GlobalMeta = dynamic(() => import('../components').then(mod => mod.GlobalMeta))
const SkipToMainContent = dynamic(() => import('../components').then(mod => mod.SkipToMainContent))
const Header = dynamic(() => import('../components').then(mod => mod.Header))
const Footer = dynamic(() => import('../components').then(mod => mod.Footer))
const Preloader = dynamic(() => import('../components').then(mod => mod.Preloader))
const GridOverlay = dynamic(() => import('../components').then(mod => mod.GridOverlay))
const PageTransition = dynamic(() => import('../components').then(mod => mod.PageTransition))
const ErrorBoundary = dynamic(() => import('../components').then(mod => mod.ErrorBoundary))

// Styles
import '../styles/fonts.css'
import '../styles/globals.scss'
import '../styles/tailwind.scss'
import '../styles/z-index.scss'

const EmptyLayout = ({ children }) => <>{children}</>

// ------------- ROOT APPLICATION -------------
export default function MainApp({ Component, pageProps }) {
  // Page data passed via pages on an individual basis
  const seo = pageProps?.pageData?.seo
  const globalMenus = pageProps?.globalData?.globalMenus
  const additionalHeaderInfo = pageProps?.additionalHeaderInfo
  const footerData = pageProps?.globalData?.globalSettings?.footer
  const footerLogos = pageProps?.globalData?.bigdGlobalSettings?.footer_logos // Logos shared across all 9 sites
  const greatPlaceToWorkLogoUrl =
    pageProps?.globalData?.bigdGlobalSettings?.great_place_to_work_logo.logo

  // Handle MegaMenu Toggle behaviour
  const [isMegaMenuActive, setMegaMenu] = useState(false)
  const hideMegaMenu = () => setMegaMenu(false)
  const toggleMegaMenu = () => setMegaMenu(!isMegaMenuActive)

  // Page transition
  const Layout = Component.Layout || EmptyLayout
  const [forceRerender, setForceRerender] = useState(null)
  const handleExitComplete = () => setForceRerender({})

  // Add global data object passed to all the child-components & pages via context API
  // const globalContextObj = { globalMenus, introEnded }

  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS

  return (
    <Provider>
      <ErrorBoundary>
        {/* Full-Screen Tint-Component when Megamenu is open */}
        <BackgroundTintLayer opacity='opacity-70' active={isMegaMenuActive} />

        {/* Page Wrapper */}
        <div
          id='main-content'
          className={clsx('flex flex-col flex-grow flex-shrink-0 min-h-screen overflow-hidden')}>
          <GlobalMeta />
          <Seo config={seo} globalData={pageProps?.globalData} />
          <Analytics />

          {GA_MEASUREMENT_ID && (
            <noscript
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
              }}></noscript>
          )}

          {process.env.NODE_ENV !== 'production' && <GridOverlay />}

          <Preloader />

          <div className='ui'>
            {/* Fixed Header */}
            <SkipToMainContent />
            <Header
              preview={pageProps?.preview}
              isMegaMenuActive={isMegaMenuActive}
              toggleMegaMenu={toggleMegaMenu}
              globalMenus={globalMenus}
              additionalHeaderInfo={additionalHeaderInfo}
            />
          </div>

          <Layout {...pageProps} exitComplete={forceRerender}>
            <PageTransition>
              {/* CORE PAGE COMPONENT GOES HERE DYNAMICALLY */}
              <Component {...pageProps} />

              <Footer
                title={footerData?.title}
                subTitle={footerData?.subtitle}
                globalMenus={globalMenus}
                footerLogos={footerLogos} // Logos shared across all 9 sites
                greatPlaceToWorkLogoUrl={greatPlaceToWorkLogoUrl}
              />
            </PageTransition>
          </Layout>
        </div>
        <MegaMenu
          isMegaMenuActive={isMegaMenuActive}
          toggleMegaMenu={toggleMegaMenu}
          globalMenus={globalMenus}
          hideMegaMenu={hideMegaMenu}
        />
      </ErrorBoundary>
    </Provider>
  )
}
