import clsx from 'clsx'
import Image from 'next/legacy/image'
import { LinkWrapper } from '../../index'
import { IMG_LAZY_LOAD_THRESHOLD, SITENAME } from '../../../utils/siteConfig'
import Styles from './FooterLogos.module.scss'

const FooterLogos = ({ footerLogos }) => {
  if (!footerLogos) return null

  const filteredFooterLogos = footerLogos.filter(logo => {
    let include = true

    if (logo.exclude_from && logo.exclude_from.length > 0) {
      for (let index = 0; index < logo.exclude_from.length; index++) {
        const website = logo.exclude_from[index]

        if (website.slug == SITENAME) {
          include = false
          break
        }
      }
    }

    return include
  })

  return (
    <ul
      className={clsx(
        'mb-70 mt-70 md:mt-20',
        'justify-between',
        'mx-auto max-w-220',
        'flex flex-wrap',
        'sm:gap-[1.8rem] sm:flex-row sm:justify-center sm:text-center sm:overflow-x-hidden sm:overflow-y-hidden'
      )}>
      {filteredFooterLogos.length &&
        filteredFooterLogos?.map(i => (
          <li key={i.title} className={clsx(
            'sm:max-w-[10%] sm:min-w-[10%] sm:w-[auto] inline-block my-30 w-4/9 text-left'
          )}>
            <LinkWrapper
              href={i.url}
              target='_blank'
              className={clsx(
                Styles.LinkWrapper,
                'grow-0 flex flex-col items-center md:flex-1',
                'transform md:w-auto md:scale-90'
              )}>
              {i?.logo?.url ? (
                <Image
                  height='45'
                  width='90' // max-width
                  src={i?.logo?.url}
                  alt={i?.logo?.alt}
                  className='object-contain'
                  lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
                />
              ) : null}
              <span
                className={clsx(
                  'text-center text-xs-A uppercase',
                  'border-b border-primary-light',
                  'pb-5 pt-30',
                  'w-5/5 2xl:w-5/5 md:w-[110%] whitespace-nowrap'
                )}>
                {i.title}
              </span>
              <div
                className='mt-10 text-center text-xss-B uppercase'
                dangerouslySetInnerHTML={{ __html: i.location }}></div>
            </LinkWrapper>
          </li>
        ))}
    </ul>
  )
}

export default FooterLogos
